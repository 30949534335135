import i18next from 'i18next';
import { inject } from 'inversify';
import { action, computed, makeObservable, observable } from 'mobx';

import { env } from '../../env';
import { singleton } from '../../inversify/decorator';
import { isAuthUserResponseDto } from '../../modules/auth/helpers';
import {
  ApplePostRequestDto, SocialPostRequestDto, UserPostLoginRequestDto, UserPostRegisterRequestDto
} from '../../shared/dto';
import { AuthUserResponseDto } from '../../shared/dto/authUser.response.dto';
import { FEATURE, MEASUREMENT_SYSTEM, SUBSCRIPTION_TYPE } from '../../shared/enum';
import { COUNTRY_CODE } from '../../shared/enum/countryCode.enum';
import { WEIGHT_UNIT } from '../../shared/enum/entryWeightUnit.enum';
import { STRIPE_SUBSCRIPTION_STATUS } from '../../shared/enum/stripe/stripeSubscriptionStatus.enum';
import { USER_TYPE } from '../../toolkit/enums/userTypeEnum';
import { AsyncTask } from '../async/AsyncTask';
import { UserLoginResponseModel } from '../model/UserLoginResponseModel';
import { UserModel } from '../model/UserModel';
import { AuthProxy } from '../proxy/AuthProxy';
import { AjaxService, IHttpResponse } from '../service/AjaxService';
import { BugsnagService } from '../service/BugsnagService';
import { NotificationService } from '../service/NotificationService';
import { WsService } from '../service/WsService';

@singleton()
export class SessionStore {

  @observable
  public session: UserLoginResponseModel | null = null;

  constructor(
    @inject(AuthProxy) private readonly authProxy: AuthProxy,
    @inject(WsService) private readonly wsService: WsService,
    @inject(BugsnagService) private readonly bugsnagService: BugsnagService,
    @inject(NotificationService) private readonly notification: NotificationService,
    @inject(AjaxService) public readonly ajax: AjaxService,
  ) {
    makeObservable(this);

    this.ajax.setAuthToken(localStorage.getItem(env.sessionTokenKey));

    if (localStorage.getItem(env.sessionTokenKey)) {
      this.recoverSession.run();
    }
  }

  @computed
  public get isAuthenticated(): boolean {
    return Boolean(this.session);
  }

  @computed
  public get hasPaymentIssues() {
    const status = this.session?.stripeSubscriptionStatus;

    if (status === undefined || status === STRIPE_SUBSCRIPTION_STATUS.ACTIVE || status === STRIPE_SUBSCRIPTION_STATUS.CANCELED || status === STRIPE_SUBSCRIPTION_STATUS.TRIALING) {
      return false;
    }

    return true;
  }


  @computed
  public get userType(): USER_TYPE {
    return this.isProUser ? USER_TYPE.PRO_USER : USER_TYPE.FREE_USER;
  }

  @computed
  public get isProUser(): boolean {
    if (!this.session) return false;

    return Boolean(this.session.user.isPro);
  }

  @computed
  public get isPlusUser(): boolean {
    return this.session?.user.subscriptionType === SUBSCRIPTION_TYPE.PLUS;
  }

  @computed
  public get isCountryUK(): boolean {
    return this.session?.user.country === COUNTRY_CODE.GB;
  }

  @computed
  public get currentMeasurementSystem(): MEASUREMENT_SYSTEM {
    return this.session?.user.distanceUnit ?? MEASUREMENT_SYSTEM.METRIC;
  }

  @computed
  public get currentWeightUnit(): WEIGHT_UNIT {
    return this.session?.user.distanceUnit === MEASUREMENT_SYSTEM.METRIC ? WEIGHT_UNIT.KILO : WEIGHT_UNIT.POUND;
  }

  public hasFeatureEnabled = (feature: FEATURE) => {
    return this.session?.subscriptions.some((s) => s.features.some((f) => f === feature)) ?? false;
  }

  @computed
  public get userId(): string {
    return this.session?.user.id ?? '';
  }

  @computed
  public get currentUser(): UserModel | undefined {
    return this.session?.user;
  }

  @computed
  public get trialPeriodUsed(): boolean {
    return Boolean(this.currentUser?.trialPeriodUsed);
  }

  @computed
  public get allowTrialPeriod(): boolean {
    return !this.trialPeriodUsed && Boolean(this.currentUser?.trialLength) && this.currentUser?.trialLength !== 0;
  }

  public login = async (data: UserPostLoginRequestDto) => {
    const result = await this.authProxy.login.run(data);
    return this.processLoginResponse(result);
  }

  public loginViaFacebook = async (data: SocialPostRequestDto) => {
    const result = await this.authProxy.loginAuthViaFacebook.run(data);
    return this.processLoginResponse(result);
  }

  public loginViaGoogle = async (data: SocialPostRequestDto) => {
    const result = await this.authProxy.loginAuthViaGoogle.run(data);
    return this.processLoginResponse(result);
  }

  public loginViaApple = async (data: ApplePostRequestDto) => {
    const result = await this.authProxy.loginAuthViaApple.run(data);
    return this.processLoginResponse(result);
  }

  public logout = async () => {
    const result = await this.authProxy.logout.run();

    if (result.ok) {
      localStorage.removeItem(`FREE_OR_PRO-${this.userId}`);
      this.removeSession();
    }

    return result;
  }

  public normalRegister = async (body: UserPostRegisterRequestDto) => {
    const result = await this.authProxy.register.run({ ...body });
    return this.processLoginResponse(result);
  }

  @action
  public setUser = (user: UserModel): void => {
    if (!this.session) return;
    this.session.user = user;
  }

  @action
  private setSession = (session: UserLoginResponseModel): void => {
    this.session = session;
    this.bugsnagService.setUser(session.user);
    localStorage.setItem(env.sessionTokenKey, session.sessionToken);
    this.ajax.setAuthToken(localStorage.getItem(env.sessionTokenKey));
    this.wsService.connect(session.sessionToken);
    this.setUserSelectedLanguage();
  }

  private processLoginResponse = (result: IHttpResponse<UserLoginResponseModel | AuthUserResponseDto>) => {
    if (result.ok && !isAuthUserResponseDto(result.data)) {
      this.setSession(result.data!);
    }

    return result;
  }

  private setUserSelectedLanguage = () => {
    const language = this.session?.user?.language;
    if (language && i18next.language !== language) {
      i18next.changeLanguage(language);
    }
  }

  public recoverSession = new AsyncTask(async () => {
    try {
      const result: IHttpResponse<UserLoginResponseModel> = await this.authProxy.recover() as IHttpResponse<UserLoginResponseModel>;

      if (result.ok) {
        this.setSession(result.data);
      } else {
        this.removeSession();
      }

      return result;
    }
    catch (error) {
      console.warn('error while recovering session: ', error);
      this.notification.error('Something went wrong with recovering session');
    }
  })

  @action
  private removeSession = () => {
    this.session = null;
    localStorage.removeItem(env.sessionTokenKey);
    this.ajax.setAuthToken(null);
    this.wsService.disconnect();
  }
}
