import { observer } from 'mobx-react-lite';
import React, { useCallback, useMemo } from 'react';
import Avatar from 'react-avatar';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Box, Grid, Link, Typography } from '@mui/material';

import { Assets } from '../../../../assets';
import { useContainer } from '../../../../domain/hooks/useContainer';
import { useVm } from '../../../../domain/hooks/useVm';
import {
  SubscriptionReceiptResponseModel
} from '../../../../domain/model/SubscriptionReceiptResponseModel';
import { TrackingEvent } from '../../../../domain/service/tracking/TrackingEvent';
import { TrackingService } from '../../../../domain/service/tracking/TrackingService';
import { AppRoutes } from '../../../../router/AppRoutesEnum';
import { SUBSCRIPTION_TYPE } from '../../../../shared/enum';
import { colors } from '../../../../theme/options/palette/const/colors';
import { palette } from '../../../../theme/options/palette/palette';
import { StoreButtons } from '../../../../toolkit/components/StoreButtons';
import { SubmitButton } from '../../../../toolkit/components/SubmitButton';
import { UserProfileProps } from '../../UserProfileRoute';
import { UserProfileButtonPartVm } from './UserProfileButtonPartVm';

export const UserProfileButtonPart = observer(function UserProfileButtonPart(props: UserProfileProps) {

  const navigate = useNavigate();
  const { t } = useTranslation();
  const tracking = useContainer().get(TrackingService);
  const vm = useVm(UserProfileButtonPartVm, props);

  const handleClick = useCallback(() => {
    tracking.track(TrackingEvent.PROFILE_MULTIPLE_SUBSCRIPTIONS_CANCEL);
  }, [tracking]);

  const logout = useCallback(() => {
    vm.sessionStore.logout().then(() => {
      navigate(AppRoutes.Login);
    });
  }, [vm.sessionStore, navigate]);

  const freeUserInfo = useMemo(() => {
    return (
      <React.Fragment>
        <img src={Assets.icon_free_user} />
        <Box display={'flex'} flexDirection={'column'}>
          <Typography variant={'subtitle2'} fontFamily="sans-serif">{t('profile:free_account')}</Typography>
        </Box>
      </React.Fragment>
    );
  }, [t]);

  const plusUserInfo = useMemo(() => {
    return (
      <React.Fragment>
        <img src={Assets.shield_grey} width={'24px'} />
        <Box display={'flex'} flexDirection={'column'}>
          <Typography variant={'subtitle2'} fontFamily="sans-serif">{t('profile:plus_account')}</Typography>
        </Box>
      </React.Fragment>
    );
  }, [t]);

  const proUserInfo = useCallback(() => {
    return (
      <React.Fragment>
        <img src={Assets.shield} width={37} style={{
          position: 'absolute',
          left: -8,
          top: -15
        }} />
        {props.userProfileVm.atMostOneRunningSubscription ? <CheckCircleOutlineIcon /> : <ErrorOutlineIcon htmlColor={colors.error} />}
        <Box display={'flex'} flexDirection={'column'}>
          {props.userProfileVm.receipts.map((receipt: SubscriptionReceiptResponseModel) => {
            return (
              <React.Fragment key={`${receipt.platform}-${receipt.expiresAt}`}>
                <Box flexDirection={'column'} color={palette.text?.entryForm} pb={1}>

                  <Typography variant={'subtitle2'} fontWeight="semibold" fontFamily="sans-serif">
                    {vm.getSubscriptionInfo(receipt)}
                  </Typography>

                  {/* GROUP OWNER */}
                  {vm.isGroupOwner(receipt) && <Typography variant={'caption'}>
                    {t('profile:group_subscription_owner')}
                  </Typography>}

                  {/* GROUP MEMBER */}
                  {vm.isGroupMember(receipt) && <Typography variant={'caption'} sx={{ lineBreak: 'anywhere' }}>
                    {`${t('profile:managed_by')}: ${vm.sessionStore.session?.groupSubscriptionOwner?.email}`}
                  </Typography>}

                  {!vm.isGroupMember(receipt) && <Typography variant={'caption'} display={'block'}>
                    {` ${vm.getPaymentPlatformTranslation(receipt)}${receipt.unsubscribeDetectedAt ? ` (${t('profile:subscription_canceled')})` : ''}`}
                  </Typography>}

                </Box>
              </React.Fragment>
            );
          })}

          {/* Info about multiple running subscriptions */}
          {vm.sessionStore.isProUser && !props.userProfileVm.atMostOneRunningSubscription && <React.Fragment>
            <Typography variant={'caption'} color={colors.error}>
              {t('profile:info.multisubscriptions_1')}
              &nbsp;
              <Link variant={'caption'} color={colors.error}
                onClickCapture={handleClick}
                target={'_blank'}
                href={t('links:help_cancel')}
                sx={{ textDecoration: 'underline' }}
              >
                {t('profile:info.multisubscriptions_2')}
              </Link>
            </Typography>
          </React.Fragment>}
        </Box>
      </React.Fragment>
    );
  }, [handleClick, props.userProfileVm.atMostOneRunningSubscription, props.userProfileVm.receipts, t, vm]);

  return (
    <Grid container direction="column" alignItems="center" rowSpacing={5}>

      {/* PHOTO */}
      <Grid item>
        <Avatar name={vm.fullName} round size="187px" src={vm.photo?.thumbnailUrl || ''} />
        <Typography align="center" variant="subtitle1" fontWeight="bold" fontFamily="sans-serif" color={palette.text?.entryForm}>{vm.firstName} {vm.lastName}</Typography>
      </Grid>

      <Grid item>
        <Box
          minWidth={242}
          minHeight={69}
          position="relative"
          display={!vm.activeSubscription && vm.sessionStore.isProUser ? 'none' : 'flex'}
          flexDirection="row"
          alignItems="center"
          justifyContent="center"
          columnGap={2}
          py={2}
          px={3}
          border={1}
          borderColor={colors.orange[400]}
          borderRadius={'14px'}
          boxShadow="0px 3px 6px #00000029"
          sx={{
            color: colors.primary,
            backgroundColor: colors.paper,
          }}
        >
          {vm.sessionStore.isProUser
            ? proUserInfo()
            : (vm.sessionStore.currentUser?.subscriptionType === SUBSCRIPTION_TYPE.PLUS
              ? plusUserInfo
              : freeUserInfo
            )
          }
        </Box>
      </Grid>

      <Grid item>
        <StoreButtons />
      </Grid>

      {/* LOGOUT */}
      <Grid item>
        <SubmitButton
          onClick={logout}
          sx={{
            width: '246px',
            height: '48px',
            backgroundColor: '#FC294B',
            '&:hover': {
              backgroundColor: '#D6042B',
            }
          }}
        >
          {t('profile:info.button_logout')}
        </SubmitButton>
      </Grid>

    </Grid>

  );
});
