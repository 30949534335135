/* eslint-disable no-prototype-builtins */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { injectable } from 'inversify';
import { action, observable } from 'mobx';

@injectable()
export abstract class ViewModel<Props = unknown> {

  @observable.ref
  protected props: Props = {} as Props;

  private areShallowEqual = (obj1: any, obj2: any) => {
    return Object.keys(obj1).length === Object.keys(obj2).length && Object.keys(obj1).every(key => {
      return obj2.hasOwnProperty(key) && obj1[key] === obj2[key];
    });
  }

  @action
  public setProps = (props: Props) => {
    if (!this.areShallowEqual(this.props, props)) {
      this.props = props;
    }
  }

  public onInit = () => {
    /** should be inherited by children classes */
  }

  public onRender = () => {
    /** should be inherited by children classes */
  }

  public onDestroy = () => {
    /** should be inherited by children classes */
  }
}
