import React from 'react';

import { TableCell } from '@mui/material';

import { GroupSubscriptionUserModel } from '../../../../../domain/model/GroupSubscriptionUserModel';
import { GroupOverviewType, isGroupOverviewWithOwner } from '../../../types/GroupOverviewTypes';
import { MemberPrice } from '../../components/MemberPrice';
import { OwnerPrice } from '../../components/OwnerPrice';

interface IPriceCellProps {
  row: GroupSubscriptionUserModel;
  groupOverviewData: GroupOverviewType;
  assignToEmptySeat: boolean;
}

export const PriceCell: React.FC<IPriceCellProps> = React.memo(function PriceCell({ row, groupOverviewData, assignToEmptySeat = false }: IPriceCellProps) {
  if (isGroupOverviewWithOwner(groupOverviewData) && groupOverviewData.owner?.id === row.id) {
    const {
      ownerPriceWithoutDiscount,
      ownerDiscount,
      ownerFinalPrice,
      currency,
    } = groupOverviewData;

    const isOwnerAlreadyPro = groupOverviewData.owner?.subscription ? Boolean(groupOverviewData.owner.isYearlySubscription) : false;

    return (
      <TableCell>
        <OwnerPrice
          isOwnerAlreadyPro={isOwnerAlreadyPro}
          ownerPriceWithoutDiscount={ownerPriceWithoutDiscount}
          ownerDiscount={ownerDiscount}
          ownerFinalPrice={ownerFinalPrice}
          currency={currency}
        />
      </TableCell>
    );
  }

  const { priceForEveryNextUser, currency } = groupOverviewData;

  return (
    <TableCell>
      <MemberPrice
        firstYearForFree={row.firstYearForFree}
        priceForEveryNextUser={priceForEveryNextUser}
        currency={currency}
        assignToEmptySeat={assignToEmptySeat}
      />
    </TableCell>
  );
});
