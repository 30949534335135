import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Box, Divider, Grid, Typography, useMediaQuery } from '@mui/material';

import { useThemeContext } from '../../../theme/ThemeContextProvider';
import { NewSubmitButton } from '../../../toolkit/components/redesign-components/NewSubmitButton';
import { PriceDisplay } from '../group-subscription/overview/PriceDisplay';
import { UpdateGroupSubscriptionVm } from './UpdateGroupSubscriptionVm';

interface IUpdateGroupPriceOverviewProps {
  updateGroupSubscriptionVm: UpdateGroupSubscriptionVm;
}

export const UpdateGroupPriceOverview = observer(function UpdateGroupPriceOverview({ updateGroupSubscriptionVm }: IUpdateGroupPriceOverviewProps) {
  const { theme } = useThemeContext();
  const isMobileSize = useMediaQuery(theme.breakpoints.down('desktop'));
  const { t } = useTranslation();
  const {
    currency,
    assignedMembers,
    emptySeatDiscount,
    assignedMembersPriceWithoutDiscount,
    assignedMembersDiscount,
    groupTotalPriceAfterDiscount,
    groupTotalPriceWithoutDiscount,
    updateGroupSubscription,
    taxRate,
    endDate,
    filledEmptySeats,
    additionalAssignedUsers,
  } = updateGroupSubscriptionVm;

  const payButton = useMemo(() => (
    <Grid item xs textAlign={'center'}>
      <NewSubmitButton
        fullWidth
        color="secondary"
        size={isMobileSize ? 'large' : 'small'}
        onClick={updateGroupSubscription.run}
        disabled={assignedMembers.size === 0}
        submitting={updateGroupSubscription.isBusy}
      >
        {t('group_subscription:price.overview.cta')}
      </NewSubmitButton>
    </Grid>
  ), [isMobileSize, updateGroupSubscription.run, updateGroupSubscription.isBusy, assignedMembers.size, t]);

  return (
    <Grid item container gap={4} justifyContent={'space-between'} rowGap={2}>
      {/* PRICE OVERVIEW */}
      <Grid item xs={12} sm={6} container direction={'column'} rowGap={2}>
        <Grid item>
          <Typography variant={isMobileSize ? 'headingSubtitleMobile' : 'text_md_semibold'} color={theme.palette.text.secondary}>
            {t('group_subscription:price.overview.title')}
          </Typography>
        </Grid>
        <Grid item container rowGap={1}>
          {filledEmptySeats !== 0 && <Grid item container justifyContent={'space-between'}>
            <Grid item>
              <Typography variant={isMobileSize ? 'text_md_semibold' : 'text_sm_semibold'} color={theme.palette.text.primary}>{filledEmptySeats}x</Typography>&nbsp;
              <Typography variant={isMobileSize ? 'text_md_regular' : 'text_sm_regular'} color={theme.palette.text.primary}>{t('group_subscription:price.overview.empty_seat')}</Typography>
            </Grid>
            <Grid item>
              <PriceDisplay
                isDiscounted={true}
                originalPrice={emptySeatDiscount}
                finalPrice={0.00}
                currency={currency}
              />
            </Grid>
          </Grid>}
          {additionalAssignedUsers !== 0 && <Grid item container justifyContent={'space-between'}>
            <Grid item>
              <Typography variant={isMobileSize ? 'text_md_semibold' : 'text_sm_semibold'} color={theme.palette.text.primary}>{additionalAssignedUsers}x</Typography>&nbsp;
              <Typography variant={isMobileSize ? 'text_md_regular' : 'text_sm_regular'} color={theme.palette.text.primary}>{t('group_subscription:table.table_row.role.member')}</Typography>
            </Grid>
            <Grid item>
              <PriceDisplay
                isDiscounted={assignedMembersDiscount !== 0}
                originalPrice={assignedMembersPriceWithoutDiscount}
                finalPrice={groupTotalPriceAfterDiscount}
                currency={currency}
              />
            </Grid>
          </Grid>}
        </Grid>
        <Grid item>
          <Divider />
        </Grid>
        <Grid item container gap={2} paddingBottom={2}>
          {/* FINAL PRICE FOR CURRENT UPGRADE */}
          <Grid item container justifyContent={'space-between'}>
            <Grid item>
              <Typography variant='text_md_semibold' color={theme.palette.text.primary}>{t('group_subscription:price.overview.total_label')}</Typography>
            </Grid>
            <Grid item textAlign={'right'}>
              <Typography variant='text_md_semibold' color={theme.palette.text.primary}>{groupTotalPriceAfterDiscount.toFixed(2)}{currency}</Typography>
              <Box>
                <Typography variant='text_xs_medium' color={theme.palette.text.secondary}>
                  {t('group_subscription:price.overview.vat', { taxRate: taxRate })}
                </Typography>
              </Box>
            </Grid>

            {/* RENEWAL FULL PRICE BILLED ON A DATE */}
            <Grid item xs={12}>
              <Typography variant={isMobileSize ? 'text_md_regular' : 'text_sm_regular'} color={theme.palette.text.secondary}>
                <Trans
                  i18nKey={'group_subscription:price.overview.renew_info'}
                  values={{
                    amount: `${groupTotalPriceWithoutDiscount.toFixed(2)}${currency}`,
                    endDate: endDate,
                  }} />
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='text_sm_regular' color={theme.palette.text.tertiary}>
              {t('group_subscription:price.overview.terms')}
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      {/* PAY BUTTON */}
      {payButton}

    </Grid>
  );
});
