import { format } from 'date-fns';
import { inject } from 'inversify';
import { action, computed, IReactionDisposer, makeObservable, observable, reaction } from 'mobx';

import { AsyncTask } from '../../../../../domain/async/AsyncTask';
import { ViewModel } from '../../../../../domain/core/ViewModel';
import { EntryModel } from '../../../../../domain/model/EntryModel';
import { EntryProxy } from '../../../../../domain/proxy/EntryProxy';
import { I18nService } from '../../../../../domain/service/I18nService';
import { NotificationService } from '../../../../../domain/service/NotificationService';
import { SessionStore } from '../../../../../domain/store/SessionStore';
import { transient } from '../../../../../inversify/decorator';
import { ENTRY_TYPE } from '../../../../../shared/enum/entryType.enum';
import { REGION } from '../../../../../shared/enum/region.enum';
import { ClassificationHelper } from '../../../../../util/classification/ClassificationHelper';
import {
  ClassificationTranslationService
} from '../../../../../util/classification/ClassificationTranslationService';
import { diseases } from '../../../../../util/classification/data/diseases';
import { EntryHelper } from '../../../../../util/EntryHelper';
import { MapDistrictsVm } from '../../../MapDistrictsVm';
import { IDiseaseListItem } from './disease/EntryDiseaseVm';

export enum EntryTabTypeEnum {
  POSITION = 'POSITION',
  CLASSIFICATION = 'CLASSIFICATION',
  DETAILS = 'DETAILS',
  FALLWILD = 'FALLWILD',
  DISEASE = 'DISEASE',
  WEAPONS_AND_AMMUNITION = 'WEAPONS_AND_AMMUNITION',
  ATTRIBUTED_TO = 'ATTRIBUTED_TO',
}

export enum EntryTabNameEnum {
  POSITION = 'entry:view.position.title',
  CLASSIFICATION = 'entry:view.classification.title',
  DETAILS = 'entry:view.details.title',
  FALLWILD = 'entry:view.cause_of_death.title',
  DISEASE = 'entry:view.disease.title',
  WEAPONS_AND_AMMUNITION = 'entry:view.weapons_and_ammunition.title',
  ATTRIBUTED_TO = 'entry:view.attributed_to.title',
}

export interface IEntryTab {
  name: string;
  type: EntryTabTypeEnum;
}

export interface IViewEntryProps {
  entry: EntryModel;
  districtsVm: MapDistrictsVm;
  upsertEntry: (entry: EntryModel) => void;
  onClose: () => void;
  onSave: AsyncTask<() => Promise<boolean>>;
  onTabChange: (tab: EntryTabTypeEnum) => void;
  onDelete: (entry: EntryModel) => void;
}

@transient()
export class ViewEntryVm extends ViewModel<IViewEntryProps> {

  private createTab = (namePath: string, type: EntryTabTypeEnum): IEntryTab => {
    return {
      name: this.i18n.t(namePath),
      type,
    };
  };

  @observable
  public regionSelection = false;

  @observable
  public currentTab: IEntryTab = this.createTab(EntryTabNameEnum.DETAILS, EntryTabTypeEnum.DETAILS);

  private tabAutoSwitchDisposer: IReactionDisposer | null = null;

  constructor(
    @inject(ClassificationTranslationService) private readonly classificationTranslationService: ClassificationTranslationService,
    @inject(ClassificationHelper) private readonly classificationHelper: ClassificationHelper,
    @inject(I18nService) public readonly i18n: I18nService,
    @inject(SessionStore) public readonly session: SessionStore,
    @inject(EntryProxy) public readonly entryProxy: EntryProxy,
    @inject(NotificationService) public readonly notification: NotificationService,
    @inject(EntryHelper) private readonly entryHelper: EntryHelper,
  ) {
    super();
    makeObservable(this);
  }

  public override onInit = () => {
    this.tabAutoSwitchDisposer = reaction(() => this.props.entry.location, (location) => {
      // when we are on position tab, if user selects location on the map, we should automatically switch to classification tab
      if (location && this.currentTab.type === EntryTabTypeEnum.POSITION && this.props.entry.positionSource === 'map') {
        this.nextTab();
      }
    });

    this.setCurrentTab(this.createTab(EntryTabNameEnum.DETAILS, EntryTabTypeEnum.DETAILS));
  }

  public override onDestroy = () => {
    this.tabAutoSwitchDisposer?.();
  }

  @action
  public setRegion = (region: REGION) => {
    const newRegion = this.props.entry.region !== region;
    this.props.entry.setRegion(region);

    if (newRegion) {
      this.props.entry.setClassification({});
    }

    this.regionSelection = false;
  }

  @action
  public openRegionSelection = () => {
    this.regionSelection = true;
  }

  @action
  public closeRegionSelection = () => {
    this.regionSelection = false;
  }

  @computed
  public get canBeSaved() {
    return !!this.props.entry.location;
  }

  @computed
  public get canEditAndDeleteEntries(): boolean {
    return this.entryHelper.canEditAndDeleteEntries(
      this.props.entry,
      this.props.districtsVm.selectedDistrict,
      this.props.districtsVm.allDistricts
    );
  }

  @computed
  public get isHarvestVisibleAndEditable(): boolean {
    return this.entryHelper.isHarvestVisibleAndEditable(this.props.entry, this.props.districtsVm.districtIds);
  }

  @computed
  public get entryTabs(): IEntryTab[] {
    const tabs: IEntryTab[] = [
      this.createTab(EntryTabNameEnum.POSITION, EntryTabTypeEnum.POSITION),
      this.createTab(EntryTabNameEnum.DETAILS, EntryTabTypeEnum.DETAILS),
      this.createTab(EntryTabNameEnum.ATTRIBUTED_TO, EntryTabTypeEnum.ATTRIBUTED_TO),
    ];

    if (this.props.entry.entryType !== ENTRY_TYPE.MISC) {
      tabs.splice(1, 0, this.createTab(EntryTabNameEnum.CLASSIFICATION, EntryTabTypeEnum.CLASSIFICATION));
    }

    if (this.canAddFallwild) {
      tabs.push(this.createTab(EntryTabNameEnum.FALLWILD, EntryTabTypeEnum.FALLWILD));
    }

    if (this.props.entry.entryType === ENTRY_TYPE.KILLING && this.diseasesForSpecies.length > 0) {
      tabs.push(this.createTab(EntryTabNameEnum.DISEASE, EntryTabTypeEnum.DISEASE));
    }

    if (this.props.entry.entryType === ENTRY_TYPE.KILLING) {
      tabs.push(this.createTab(EntryTabNameEnum.WEAPONS_AND_AMMUNITION, EntryTabTypeEnum.WEAPONS_AND_AMMUNITION));
    }

    return tabs;
  }

  @computed
  public get canAddFallwild() {
    return this.props.entry.entryType === ENTRY_TYPE.KILLING &&
      (this.props.entry.classificationObject?.species || this.props.entry.classificationObject?.group);
  }

  @computed
  public get diseasesForSpecies(): IDiseaseListItem[] {
    if (!this.props.entry.classificationObject?.species || this.props.entry.entryType !== ENTRY_TYPE.KILLING) {
      return [];
    }

    const region = this.props.entry.region ?? REGION.dach;
    if (!Object.prototype.hasOwnProperty.call(diseases, region)) {
      return [];
    }

    return diseases[region].children
      .find(item => item.species === this.props.entry.classificationObject?.species)?.diseases
      .map(disease => {
        return {
          key: disease.name,
          value: this.i18n.t(`diseases:${disease.name}`)
        };
      }) ?? [];
  }

  @computed
  public get ownerName(): string {
    return this.props.entry.ownerName ?? '';
  }

  @computed
  public get classificationDescription(): string | undefined {
    return this.classificationTranslationService.getFullTranslationForEntry(this.props.entry);
  }

  @computed
  public get entryColor(): string {
    return this.classificationHelper.getEntryColor(this.props.entry.entryType);
  }

  @computed
  public get entryBackground(): string {
    return this.classificationHelper.getEntryViewBackground(this.props.entry.entryType);
  }

  @computed
  public get entryIcon(): string {
    return this.classificationHelper.getClassificationOrEntryTypeIcon(this.props.entry.classificationObject, this.props.entry.entryType);
  }

  @action
  public setCurrentTab(entryTab: IEntryTab) {
    this.currentTab = entryTab;
    this.props.onTabChange(entryTab.type);
  }

  @action
  public nextTab() {
    const index = this.entryTabs.findIndex((t) => t.type === this.currentTab.type);
    if (index !== -1) {
      if (this.entryTabs.length > index) {
        this.setCurrentTab(this.entryTabs[index + 1]);
      }
    }
  }

  public entryDate = (pattern: string): string => {
    if (!this.props.entry.userDate) return '';
    return format(this.props.entry.userDate, pattern);
  }

  @action
  public handleSaveClick = () => {
    if (this.currentTab.type === EntryTabTypeEnum.POSITION || this.currentTab.type === EntryTabTypeEnum.CLASSIFICATION) {
      this.nextTab();
    } else {
      this.props.onSave.run();
    }
  }

}
