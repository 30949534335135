import { endOfDay, isBefore, isEqual, startOfDay } from 'date-fns';
import { inject } from 'inversify';
import { action, computed, makeObservable, observable } from 'mobx';

import { AsyncTask } from '../../../../../../../../domain/async/AsyncTask';
import { ViewModel } from '../../../../../../../../domain/core/ViewModel';
import {
  QuotaPlanModel, QuotaPlanSpecieModel
} from '../../../../../../../../domain/model/QuotaPlanModel';
import { QuotaPlanProxy } from '../../../../../../../../domain/proxy/QuotaPlanProxy';
import { I18nService } from '../../../../../../../../domain/service/I18nService';
import { NotificationService } from '../../../../../../../../domain/service/NotificationService';
import { TrackingEvent } from '../../../../../../../../domain/service/tracking/TrackingEvent';
import { TrackingService } from '../../../../../../../../domain/service/tracking/TrackingService';
import { transient } from '../../../../../../../../inversify/decorator';
import { QuotaPlanStatus } from '../../../../../../../../shared/enum/quotaPlanStatus.enum';
import { deepEqual } from '../../../../../../../../util/MapUtils';
import { IViewQuotaPlanProps } from './ViewQuotaPlan';

export interface IViewQuotaPlanVmProps extends IViewQuotaPlanProps {
  setDisplayChangeRegionButton: (value: boolean) => void
}

@transient()
export class ViewQuotaPlanVm extends ViewModel<IViewQuotaPlanVmProps> {

  @observable
  public tempQuotaPlan: QuotaPlanModel = new QuotaPlanModel();

  @observable
  public tempQuotaPlanSpecie: QuotaPlanSpecieModel = new QuotaPlanSpecieModel();

  public currentDate = new Date();

  @observable
  public from: Date | null = null;

  @observable
  public to: Date | null = null;

  @observable
  public isAnimalClassificationOpened: boolean = false;

  @observable
  private existingSpecieAmountChanged: boolean = false;

  constructor(
    @inject(NotificationService) private readonly notification: NotificationService,
    @inject(QuotaPlanProxy) private readonly quotaProxy: QuotaPlanProxy,
    @inject(I18nService) public readonly i18n: I18nService,
    @inject(TrackingService) public readonly tracking: TrackingService,
  ) {
    super();
    makeObservable(this);
  }

  public override onInit = () => {
    this.setTempQuotaPlan(this.props.quotaPlan.clone());
    this.tracking.track(this.props.quotaPlan.id ? TrackingEvent.QUOTA_EDIT : TrackingEvent.QUOTA_STARTED);
    this.setDatesFromProps(this.props.quotaPlan.startDate, this.props.quotaPlan.endDate);
  }

  @computed
  public get hasDateChanged() {
    if (this.from && this.to) {
      return !isEqual(this.props.quotaPlan.startDate, this.tempQuotaPlan.startDate) || !isEqual(this.props.quotaPlan.endDate, this.tempQuotaPlan.endDate);
    }
    return false;
  }

  @computed
  public get isFormDirty(): boolean {
    const speciesChanged = !deepEqual<QuotaPlanSpecieModel[]>(this.props.quotaPlan.species, this.tempQuotaPlan.species) && this.props.quotaPlan.species.length !== this.tempQuotaPlan.species.length;
    return (this.props.quotaPlan.name !== this.tempQuotaPlan.name) || this.hasDateChanged || speciesChanged || this.existingSpecieAmountChanged;
  }

  @computed
  public get isPublishDisabled(): boolean {
    if (this.tempQuotaPlan.id) {
      return this.tempQuotaPlan.name.length === 0 || !this.isFormDirty || !this.tempQuotaPlan.species.length;
    } else {
      const { name, startDate, endDate, species } = this.tempQuotaPlan;
      return !name || !startDate || !endDate || species.length === 0;
    }
  }

  @computed
  public get isPastPlan(): boolean {
    return this.props.quotaPlan.status === QuotaPlanStatus.PAST;
  }

  @computed
  public get enableAddAnimalButton() {
    return Object.keys(this.tempQuotaPlanSpecie.classificationObject).some(key => key !== undefined);
  }

  @action
  public setTempQuotaPlan = (quotaPlan: QuotaPlanModel) => {
    this.tempQuotaPlan = quotaPlan;
  }

  @action
  public setTempQuotaPlanSpecie = (quotaPlanSpecie: QuotaPlanSpecieModel) => {
    this.tempQuotaPlanSpecie = quotaPlanSpecie;
  }

  @computed
  private get defaultStartDate(): Date {
    const now = new Date(this.currentDate);

    // 1st of April of the current year
    const startOfHuntingSeason = new Date(this.currentDate.getFullYear(), 3, 1);

    if (isBefore(now, new Date(this.currentDate.getFullYear(), 2, 30))) {
      // If before 30th of March of the current year, use 1st April of the previous year
      return startOfDay(new Date(this.currentDate.getFullYear() - 1, 3, 1));
    }

    return startOfDay(startOfHuntingSeason);
  }

  @computed
  private get defaultEndDate(): Date {
    // 30th of March of the next year
    const endOfHuntingSeason = new Date(this.currentDate.getFullYear() + 1, 2, 30);

    return startOfDay(endOfHuntingSeason);
  }

  @action
  private setDatesFromProps = (startDate: Date | null, endDate: Date | null) => {
    const start = startDate ? startOfDay(startDate) : this.defaultStartDate;
    const end = endDate ? endOfDay(endDate) : this.defaultEndDate;
    this.setFrom(start);
    this.setTo(end);
  }

  @action
  public resetDates = () => {
    this.from = startOfDay(this.props.quotaPlan.startDate);
    this.to = endOfDay(this.props.quotaPlan.endDate);
  }

  @action
  public setFrom = (from: Date) => {
    this.from = startOfDay(from);
    this.tempQuotaPlan.setStartDate(this.from);
  }

  @action
  public setTo = (to: Date) => {
    this.to = endOfDay(to);
    this.tempQuotaPlan.setEndDate(this.to);
  }

  @action
  private setExistingSpecieAmountChange = (changed: boolean) => {
    this.existingSpecieAmountChanged = changed;
  }

  @action
  public addAnimalToQuotaPlan = () => {
    const existingIndex = this.findSpeciesIndex(this.tempQuotaPlanSpecie);
    if (existingIndex !== -1) {
      this.tempQuotaPlan.species[existingIndex].changeAmount(this.tempQuotaPlan.species[existingIndex].amount + this.tempQuotaPlanSpecie.amount);
      this.setExistingSpecieAmountChange(true);
    } else {
      this.tempQuotaPlan.species.push(this.tempQuotaPlanSpecie);
    }

    this.tracking.track(TrackingEvent.QUOTA_ANIMAL_ADDED, { dimension: { specie: this.tempQuotaPlanSpecie.classificationObject, amount: this.tempQuotaPlanSpecie.amount } });
    this.isAnimalClassificationOpened = false;
    this.props.setDisplayChangeRegionButton(false);
    this.setTempQuotaPlanSpecie(new QuotaPlanSpecieModel());
  }

  private findSpeciesIndex(specie: QuotaPlanSpecieModel): number {
    return this.tempQuotaPlan.species.findIndex(s => {
      return deepEqual(specie.classificationObject, s.classificationObject);
    });
  }

  @action
  public removeAnimalFromQuotaPlan = (specie: QuotaPlanSpecieModel) => {
    const newSpeciesList = this.tempQuotaPlan.species.filter(s => s !== specie);
    this.tempQuotaPlan.setSpeciesList(newSpeciesList);
  }

  @action
  public upsert = (quotaPlan: QuotaPlanModel) => {
    const index = this.props.quotaPlans.findIndex((qp) => qp.id === quotaPlan.id);

    if (index === -1) {
      this.props.quotaPlans.unshift(quotaPlan);
    } else {
      this.props.quotaPlans.splice(index, 1, quotaPlan);
    }
  }

  public publishQuotaPlan = new AsyncTask(async () => {
    try {
      const result = this.tempQuotaPlan.id
        ? await this.quotaProxy.updateQuotaPlan(this.props.quotaPlan.toPutDto(this.tempQuotaPlan))
        : await this.quotaProxy.createQuotaPlan(this.props.quotaPlan.toPostDto(this.tempQuotaPlan));

      if (result.ok) {
        this.tracking.track(TrackingEvent.QUOTA_COMPLETED);
        this.upsert(result.data);
        this.notification.success(this.i18n.t('quota_plan:success.publish_plan'));
        return true;
      } else {
        this.notification.error(this.i18n.t(`quota_plan:${result.message}`));
        return false;
      }
    } catch (error) {
      console.error('Something went wrong while trying to publish quota plan: ', error);
      this.notification.error(this.i18n.t('quota_plan:error.publish_plan'));
    }
  })

}
