import { inject } from 'inversify';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';

import { AsyncTask } from '../../../../../domain/async/AsyncTask';
import { ViewModel } from '../../../../../domain/core/ViewModel';
import { GroupSubscriptionUserModel } from '../../../../../domain/model/GroupSubscriptionUserModel';
import { StripeService } from '../../../../../domain/service/StripeService';
import { transient } from '../../../../../inversify/decorator';
import { AssignSeatOptionType, IAssignAreaMembersModalProps } from './AssignAreaMembersModal';

@transient()
export class AssignAreaMembersModalVm extends ViewModel<IAssignAreaMembersModalProps> {

  @observable
  public open: boolean = false;

  @observable
  public isEmailValid: boolean = false;

  @observable
  public emailErrorMsg: string = '';

  @observable
  public assignSeatOption: AssignSeatOptionType = 'existing_members';

  public assignSeatOptions: AssignSeatOptionType[] = [
    'existing_members',
    // 'email_invite'
  ];

  @observable
  public tempUsers: Set<GroupSubscriptionUserModel> = new Set();

  @observable
  public userEmail: string = '';

  constructor(
    @inject(StripeService) private readonly stripeService: StripeService,
  ) {
    super();
    makeObservable(this);
  }

  @computed
  public get disableAssign(): boolean {
    return !this.isEmailInvite && Boolean(!this.tempUsers.size);
  }

  @computed
  public get isEmailInvite(): boolean {
    return this.assignSeatOption === 'email_invite';
  }


  @action
  public setOpenModal = (open: boolean) => {
    this.open = open;

    if (open) {
      // Initialize the assignSeatOption based on props.assignOption when the modal opens
      this.assignSeatOption = this.props.assignOption;
    }

  }

  @action
  public setAssignSeatOption = (option: AssignSeatOptionType) => {
    this.assignSeatOption = option;

    if (option === 'email_invite') {
      this.setTempUsers(new Set());
    }
  }

  @action
  public setTempUsers = (users: Set<GroupSubscriptionUserModel>) => {
    this.tempUsers = users;
  }

  @action
  public setUserEmail = (email: string) => {
    this.userEmail = email;
  }

  @action
  public setIsEmailValid = (isEmailValid: boolean) => {
    this.isEmailValid = isEmailValid;
  }

  @action
  public setEmailErrorMsg = (errorMsg: string) => {
    this.emailErrorMsg = errorMsg;
  }

  public onAssignSeats = () => {
    const { assignedMembers, setAssignedMembers } = this.props;

    const combinedSet = new Set([...Array.from(assignedMembers), ...Array.from(this.tempUsers)]);

    setAssignedMembers(combinedSet);

    this.closeModal();
  };

  public onAssignViaEmail = new AsyncTask(async () => {
    try {
      const response = await this.stripeService.validateUserEmail(this.userEmail);
      if (response?.ok) {
        runInAction(() => {
          this.props.assignedMembers.add(response.data);
        });
        this.closeModal();
      } else {
        this.setEmailErrorMsg(response?.message || 'Unknown error');
      }
    } catch (error) {
      this.setEmailErrorMsg('Network error. Please try again.');
    }
  })

  @action
  public resetModalState = () => {
    this.setOpenModal(false);
    this.setTempUsers(new Set());
    this.setUserEmail('');
    this.setEmailErrorMsg('');
  };

  public closeModal = () => {
    this.resetModalState();
    this.props.onCloseModal();
  }

}
