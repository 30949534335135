import { observer } from 'mobx-react-lite';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import {
  Button, Grid, TableBody, TableCell, TableRow, Tooltip, Typography, useMediaQuery
} from '@mui/material';

import { useTablePagination } from '../../../../domain/hooks/useTablePagination';
import { GroupSubscriptionUserModel } from '../../../../domain/model/GroupSubscriptionUserModel';
import { AppRoutes } from '../../../../router/AppRoutesEnum';
import { useThemeContext } from '../../../../theme/ThemeContextProvider';
import { TableLayout } from '../../../../toolkit/components/table/TableLayout';
import { ManageGroupVm } from '../ManageGroupVm';
import { ManageGroupOverviewTableHead } from './ManageGroupOverviewTableHead';
import { ManageGroupOverviewTableRow } from './ManageGroupOverviewTableRow';

interface IManageGroupOverviewTableProps {
  manageGroupVm: ManageGroupVm;
}

export const ManageGroupOverviewTable = observer(function ManageGroupOverviewTable({ manageGroupVm }: IManageGroupOverviewTableProps) {
  const { page, rowsPerPage, handleChangePage, handleChangeRowsPerPage, emptyRows, visibleRows } = useTablePagination<GroupSubscriptionUserModel>(5);
  const { allParticipantsOfGroup } = manageGroupVm;

  const { theme } = useThemeContext();
  const isMobileSize = useMediaQuery(theme.breakpoints.down('desktop'));
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleAddSeats = useCallback(() => {
    navigate(AppRoutes.AddMembersToGroup);
  }, [navigate]);


  const renderAddMemberButton = useMemo(() => {
    const button = (
      <Grid item>
        <Button
          size={isMobileSize ? 'large' : 'small'}
          variant="contained"
          disableElevation
          onClick={handleAddSeats}
          disabled={Boolean(manageGroupVm.groupSubscriptionInfo.canceledAt)}
          startIcon={<PersonOutlineOutlinedIcon htmlColor={theme.palette.system?.white} />}
          sx={{ minWidth: '145px' }}
        >
          {t('group_subscription:manage_group.button.cta')}
        </Button>
      </Grid>
    );

    return manageGroupVm.groupSubscriptionInfo.canceledAt ? (
      <Tooltip arrow placement='top' title={t('manage_group:members.canceled_sub_tooltip')}>
        {button}
      </Tooltip>
    ) : (
      <Grid item>{button}</Grid>
    );
  }, [handleAddSeats, isMobileSize, manageGroupVm.groupSubscriptionInfo.canceledAt, t, theme.palette.system?.white]);

  const manageGroupOverviewTableHead = useMemo(() => <ManageGroupOverviewTableHead />, []);

  const memoizedRows = useMemo(
    () => visibleRows(Array.from(allParticipantsOfGroup)).map((row, index) => (
      <ManageGroupOverviewTableRow
        key={row.key}
        index={index}
        row={row}
        vm={manageGroupVm}
      />
    )),
    [allParticipantsOfGroup, manageGroupVm, visibleRows]
  );

  return (
    <Grid container rowGap={2}>
      {/* HEADER */}
      <Grid item container justifyContent={'space-between'} alignItems={'flex-end'} pt={2}>
        <Grid item>
          <Typography variant={'text_md_semibold'} color={theme.palette.text.primary}>
            {t('group_subscription:manage_group.seats_overview')}
          </Typography>
        </Grid>
        <Grid item>
          {renderAddMemberButton}
        </Grid>
      </Grid>

      {/* TABLE */}
      <TableLayout
        count={allParticipantsOfGroup.size}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      >
        {manageGroupOverviewTableHead}
        <TableBody>
          {memoizedRows}
          {emptyRows(allParticipantsOfGroup.size) > 0 && (
            <TableRow style={{ height: 53 * emptyRows(allParticipantsOfGroup.size) }}>
              <TableCell colSpan={6} />
            </TableRow>
          )}
        </TableBody>
      </TableLayout>
    </Grid>
  );
});
