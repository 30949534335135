import { inject } from 'inversify';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';

import { AsyncTask } from '../../../../../../domain/async/AsyncTask';
import { ViewModel } from '../../../../../../domain/core/ViewModel';
import { EntryModel } from '../../../../../../domain/model/EntryModel';
import { ClassificationProxy } from '../../../../../../domain/proxy/ClassificationProxy';
import { I18nService } from '../../../../../../domain/service/I18nService';
import { transient } from '../../../../../../inversify/decorator';
import { ENTRY_TYPE } from '../../../../../../shared/enum';
import { LANGUAGE_REGION } from '../../../../../../shared/enum/languageRegion.enum';
import { ClassificationHelper } from '../../../../../../util/classification/ClassificationHelper';
import { createTree } from '../../../../../../util/classification/ClassificationUtils';
import {
  IClassification, IClassificationData
} from '../../../../../../util/classification/types/classification.types';

export interface IClassificationButton {
  name: string;
  selected: boolean;
  callback?: () => void;
  icon?: string;
}

export interface IEntryClassificationProps {
  languageRegion: LANGUAGE_REGION;
  entry: EntryModel;
  canEditAndDeleteEntries: boolean;
  isHarvestVisibleAndEditable: boolean;
}

@transient()
export class EntryClassificationVm extends ViewModel<IEntryClassificationProps> {

  @observable
  private classificationData: IClassificationData | null = null;

  constructor(
    @inject(ClassificationProxy) private readonly classificationProxy: ClassificationProxy,
    @inject(ClassificationHelper) private readonly classificationHelper: ClassificationHelper,
    @inject(I18nService) private readonly i18n: I18nService,
  ) {
    super();
    makeObservable(this);
  }

  @computed
  public get language() {
    return this.i18n.language;
  }

  @action
  public override onInit = () => {
    this.loadClassificationData.run();
  }

  public loadClassificationData = new AsyncTask(async () => {
    try {
      const response = await this.classificationProxy.getClassification(this.props.languageRegion);

      if (response.status === 200) {
        return runInAction(() => {
          this.classificationData = response.data;
        });
      }

      console.warn(`error while loading region data. status: ${response.status}`);
    } catch (e) {
      console.error(`exception while loading region data. ${e}`);
    }
  })

  // We are sorting the classification object to keep rendering order
  @computed
  public get classificationObject(): IClassification {

    if (this.props.entry.entryType === ENTRY_TYPE.MISC) {
      return {};
    }

    const sortedClassification: string[] = [
      'group',
      'species',
      'age',
      'gender',
      'class'
    ];

    const entries = Object.entries(this.props.entry.classificationObject);

    entries.sort((x, y) => {
      const categoryX = x[0];
      const indexOfX = sortedClassification.indexOf(categoryX);
      const categoryY = y[0];
      const indexOfY = sortedClassification.indexOf(categoryY);
      if (indexOfX < indexOfY) {
        return -1;
      }
      if (indexOfX > indexOfY) {
        return 1;
      }
      return 0;
    });

    const newClassificationObject: { [key: string]: unknown } = {};

    for (const entry of entries) {
      newClassificationObject[entry[0]] = entry[1];
    }

    return newClassificationObject as IClassification;
  }

  @computed
  public get selectedFirstLevel(): IClassificationButton | undefined {
    if (this.tree.length > 0) {
      return this.tree[0].find((item) => item.selected);
    }
  }

  @computed
  public get selectedOtherLevels() {
    /**
     * This modification is happening synchronously, so it's not about the speed of execution but rather about mutating the array in place.
     * The issue with using splice(1) was modifying the original array. So instead, we use slice(1). Unlike splice, the slice method doesn't modify the original array; it returns a new array containing the selected elements.
     * With this change, this.tree remains unchanged, and selectedOtherLevels will correctly retrieve selected buttons from the rows after the first one.
    */
    return this.tree.slice(1)
      .map((row) => {
        return row.find((item) => item.selected);
      })
      .filter((r) => !!r);
  }

  @computed
  public get tree(): IClassificationButton[][] {
    if (!this.classificationData) {
      return [];
    }

    if (this.props.entry.entryType === ENTRY_TYPE.KILLING || this.props.entry.entryType === ENTRY_TYPE.SIGHTING) {
      // pass classificationObject over here
      return createTree(this.classificationData.children, undefined, undefined, this.classificationObject, this.classificationHelper, this.props.entry.setClassification);

    }

    return [];
  }

}
