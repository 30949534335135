import React, { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import { IconButton, Menu, MenuItem, TableCell } from '@mui/material';

import { DeleteDialog, DeleteDialogRef } from '../../../../../../toolkit/components/DeleteDialog';
import { ManageGroupVm } from '../../../../manage-group/ManageGroupVm';

interface IMenuCellCancelSeatProps {
  index: number;
  vm: ManageGroupVm;
}

export const MenuCellCancelSeat: React.FC<IMenuCellCancelSeatProps> = function MenuCellCancelSeat({ index, vm }) {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const cancelSeatDialogRef = useRef<DeleteDialogRef>(null);

  const showMenu = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const closeMenu = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const openCancelSeatDialog = useCallback(() => {
    closeMenu();
    cancelSeatDialogRef.current?.handleOpenDeleteDialog();
  }, [closeMenu]);

  const handleCancelSeatConfirm = useCallback(async () => {
    await vm.removeUnassignedSeat.run();
  }, [vm.removeUnassignedSeat]);

  return (
    <TableCell component="th" scope="row" padding="none" sx={{ paddingX: 2 }} align="right">
      <IconButton onClick={showMenu} disabled={index === 0}>
        <MoreVertIcon />
      </IconButton>

      <Menu
        id="cancel-seat-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={closeMenu}
      >
        <MenuItem onClick={openCancelSeatDialog}>
          {t('group_subscription:table.table_row.action.cancel_seat')}
        </MenuItem>
      </Menu>

      <DeleteDialog
        ref={cancelSeatDialogRef}
        locizeNamespace="manage_group"
        onDelete={handleCancelSeatConfirm}
        titleKey={'cancel_seat.title'}
        descriptionKey={'cancel_seat.description'}
      />
    </TableCell>
  );
};
