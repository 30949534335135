import { ApiProperty } from "@nestjs/swagger";
import { PAYMENT_PLATFORM } from '../../../shared/enum/paymentPlatform.enum';
import { IsEnum } from "class-validator";
import { RC_PERIOD_TYPE_ENUM } from "../../enum/revenuecat.period.type.enum";
import { RC_WEBHOOK_EVENT_TYPE } from '../../enum/revenuecat.webhook.event.type.enum';
import { SUBSCRIPTION_TYPE } from "../../../shared/enum/subscriptionType.enum";

export class SubscriptionReceiptResponseDto {

  @IsEnum(PAYMENT_PLATFORM)
  @ApiProperty({ enum: PAYMENT_PLATFORM })
  public platform: PAYMENT_PLATFORM;

  @ApiProperty()
  public purchasedAt: string | null;

  @ApiProperty()
  public expiresAt: string | null;

  @ApiProperty()
  public active: boolean;

  @ApiProperty()
  public productId?: string;

  @ApiProperty()
  public periodType?: RC_PERIOD_TYPE_ENUM;

  @ApiProperty()
  public type?: RC_WEBHOOK_EVENT_TYPE;

  @ApiProperty({ description: 'Date as ISO string' })
  public unsubscribeDetectedAt?: string | null;

  @ApiProperty()
  public subscriptionType?: SUBSCRIPTION_TYPE | null;
}
