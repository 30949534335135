import { inject } from 'inversify';
import { action, makeObservable, observable, runInAction } from 'mobx';
import { Assets } from '../../../../../../../../assets';
import { ViewModel } from '../../../../../../../../domain/core/ViewModel';
import { ClassificationProxy } from '../../../../../../../../domain/proxy/ClassificationProxy';
import { I18nService } from '../../../../../../../../domain/service/I18nService';
import { transient } from '../../../../../../../../inversify/decorator';
import { REGION } from '../../../../../../../../shared/enum/region.enum';
import { ClassificationHelper } from '../../../../../../../../util/classification/ClassificationHelper';
import { ClassificationTranslationService } from '../../../../../../../../util/classification/ClassificationTranslationService';
import { toLanguageRegion } from '../../../../../../../../util/classification/ClassificationUtils';
import { IClassificationData } from '../../../../../../../../util/classification/types/classification.types';

interface RegionAnimal {
  icon: string;
  name: string;
}

interface RegionInfo {
  region: REGION;
  image: string;
  count: number | null;
  animals: RegionAnimal[];
}

export interface ClassificationRegionProps {
  headerColor: string;
  region: REGION | undefined;
  onRegionChange: (region: REGION) => void;
  onClose: () => void;
}

@transient()
export class ClassificationRegionVm extends ViewModel<ClassificationRegionProps> {

  @observable
  public selectedRegion: RegionInfo | null = null;

  @observable
  public regions: RegionInfo[] = [{
    region: REGION.africa,
    image: Assets.region.africa,
    count: null,
    animals: []
  }, {
    region: REGION.asia,
    image: Assets.region.asia,
    count: null,
    animals: []
  }, {
    region: REGION.western_europe,
    image: Assets.region.western_europe,
    count: null,
    animals: []
  }, {
    region: REGION.british_isles,
    image: Assets.region.british_isles,
    count: null,
    animals: []
  }, {
    region: REGION.dach,
    image: Assets.region.dach,
    count: null,
    animals: []
  }, {
    region: REGION.bulgaria,
    image: Assets.region.bulgaria,
    count: null,
    animals: []
  }, {
    region: REGION.eastern_europe,
    image: Assets.region.eastern_europe,
    count: null,
    animals: []
  }, {
    region: REGION.nordics,
    image: Assets.region.nordics,
    count: null,
    animals: []
  }, {
    region: REGION.north_america,
    image: Assets.region.north_america,
    count: null,
    animals: []
  }, {
    region: REGION.oceania,
    image: Assets.region.oceania,
    count: null,
    animals: []
  }, {
    region: REGION.south_america,
    image: Assets.region.south_america,
    count: null,
    animals: []
  }, {
    region: REGION.southern_europe,
    image: Assets.region.southern_europe,
    count: null,
    animals: []
  }, {
    region: REGION.malta,
    image: Assets.region.malta,
    count: null,
    animals: []
  }];

  constructor(
    @inject(ClassificationHelper) private readonly classificationHelper: ClassificationHelper,
    @inject(ClassificationProxy) private readonly classificationProxy: ClassificationProxy,
    @inject(I18nService) private readonly i18n: I18nService,
    @inject(ClassificationTranslationService) public readonly classificationTranslationService: ClassificationTranslationService,
  ) {
    super();
    makeObservable(this);
  }

  public override onInit = () => {
    this.onRegionChange(this.props.region ?? REGION.dach);
    this.loadRegionData();
  }

  private loadRegionData = async () => {
    for (const region of this.regions) {
      const regionData = await this.getClassificationData(region.region);
      runInAction(() => {
        region.count = this.animalCountForClassification(regionData);
        region.animals = this.animalsForClassification(regionData);
      });
    }
  }

  public animalCountForClassification = (classificationData: IClassificationData): number => {
    let count = 0;

    classificationData.children.forEach((data) => {
      if (data.classification.group && data.children) {
        count += data.children.length;
        return;
      }

      count++;
    });

    return count;
  }

  private animalsForClassification = (classificationData?: IClassificationData): RegionAnimal[] => {
    const animals: RegionAnimal[] = [];

    if (classificationData && classificationData.children) {
      classificationData.children.forEach((l1ClassificationNode) => {
        if (l1ClassificationNode.classification.group && l1ClassificationNode.children) {
          return l1ClassificationNode.children.forEach((l2ClassificationNode) => {
            return animals.push({
              icon: this.classificationHelper.getClassificationIcon(l2ClassificationNode.classification, true),
              name: l2ClassificationNode.name,
            });
          });
        }

        return animals.push({
          icon: this.classificationHelper.getClassificationIcon(l1ClassificationNode.classification, true),
          name: l1ClassificationNode.name,
        });
      });
    }

    return animals;
  }

  public getClassificationData = async (region: REGION): Promise<IClassificationData> => {
    const response = await this.classificationProxy.getClassification(toLanguageRegion(this.i18n.language, region));
    if (response.status !== 200) {
      return { children: [] };
    }

    const classificationData = response.data;
    if (!classificationData || !classificationData.children) {
      return { children: [] };
    }

    return classificationData;
  }

  @action
  public onRegionChange = (region: REGION) => {
    this.selectedRegion = this.regions.find((r) => r.region === region) ?? null;
  }
}
