import { add, format } from 'date-fns';
import { inject } from 'inversify';
import { action, computed, makeObservable, observable } from 'mobx';
import { NavigateFunction } from 'react-router';

import { AsyncTask } from '../../../domain/async/AsyncTask';
import { GroupSubscriptionModel } from '../../../domain/model/GroupSubscriptionModel';
import { GroupSubscriptionUserModel } from '../../../domain/model/GroupSubscriptionUserModel';
import { StripeInvoiceModel } from '../../../domain/model/stripe/StripeInvoiceModel';
import { I18nService } from '../../../domain/service/I18nService';
import { NotificationService } from '../../../domain/service/NotificationService';
import { StripeService } from '../../../domain/service/StripeService';
import { WsService } from '../../../domain/service/WsService';
import { SessionStore } from '../../../domain/store/SessionStore';
import { transient } from '../../../inversify/decorator';
import { Types } from '../../../inversify/types';
import { AppRoutes } from '../../../router/AppRoutesEnum';
import {
  STRIPE_SUBSCRIPTION_STATUS
} from '../../../shared/enum/stripe/stripeSubscriptionStatus.enum';
import { WsEvent } from '../../../shared/ws/ws.event';
import { GroupPricingVm } from '../GroupPricingVm';
import { IGroupOverviewMembersOnly } from '../types/GroupOverviewTypes';

@transient()
export class UpdateGroupSubscriptionVm extends GroupPricingVm {

  @observable
  public groupSubscriptionInfo: GroupSubscriptionModel = new GroupSubscriptionModel();

  /** This is for a NEXT invoice amount. Calculation is done by Stripe. */
  @observable
  public upcomingInvoice: StripeInvoiceModel = new StripeInvoiceModel();

  constructor(
    @inject(I18nService) protected override readonly i18n: I18nService,
    @inject(NotificationService) protected override readonly notification: NotificationService,
    @inject(StripeService) protected override readonly stripeService: StripeService,
    @inject(WsService) private readonly wsService: WsService,
    @inject(Types.Navigate) private readonly navigate: NavigateFunction,
    @inject(SessionStore) public override readonly sessionStore: SessionStore,
  ) {
    super(stripeService, i18n, notification, sessionStore);
    makeObservable(this);
  }

  public override onInit = async () => {
    await this.fetchData.run();
    this.wsService.registerHandler(WsEvent.UserStripeChargeSucceeded, this.rerouteUser);
    this.wsService.registerHandler(WsEvent.UserStripeChargePending, this.rerouteUser);
  }

  public override onDestroy = () => {
    this.wsService.deregisterHandler(WsEvent.UserStripeChargeSucceeded, this.updateGroupSubscription.run);
    this.wsService.deregisterHandler(WsEvent.UserStripeChargePending, this.updateGroupSubscription.run);
  }

  public override fetchData = new AsyncTask(async () => {
    await Promise.all([
      this.getProducts.run(),
      this.getSubscriptionInfo.run(),
      this.getRelatedMembers.run(),
      this.getUpcomingInvoice.run(),
    ]);
  })

  // * When upgrading subscription is successful or SEPA payment is pending, re-route user to manage group screen
  private rerouteUser = () => setTimeout(() => this.navigate(AppRoutes.ManageGroup), 3000);

  @action
  public setGroupSubscriptionInfo = (groupSubscriptionInfo: GroupSubscriptionModel) => {
    this.groupSubscriptionInfo = groupSubscriptionInfo;
  }

  @action
  public setUpcomingInvoice = (upcomingInvoice: StripeInvoiceModel) => {
    this.upcomingInvoice = upcomingInvoice;
  }

  @computed
  public get endDate(): string {
    if (this.groupSubscriptionInfo.expiresAt) {
      return format(new Date(this.groupSubscriptionInfo.expiresAt), 'dd.MM.yyyy');
    }

    return format(add(new Date(), { years: 1 }), 'dd.MM.yyyy');
  }

  @computed
  public get taxRate(): string {
    return `${this.upcomingInvoice.taxRate}%`;
  }

  /** Represents all selected users in overview table */
  @computed
  public get groupMembersOverview(): Set<GroupSubscriptionUserModel> {
    return new Set([...Array.from(this.assignedMembers)]);
  }

  @computed
  public get filledEmptySeats(): number {
    return Math.min(this.groupSubscriptionInfo.emptySeats, this.groupMembersOverview.size);
  }

  @computed
  public get emptySeatDiscount(): number {
    return this.priceForEveryNextUser * this.filledEmptySeats;
  }

  /** Number of assigned users when empty seats are fulfilled */
  @computed
  public get additionalAssignedUsers(): number {
    return this.groupMembersOverview.size - this.filledEmptySeats;
  }

  public override get assignedMembersPriceWithoutDiscount(): number {
    return this.additionalAssignedUsers * this.priceForEveryNextUser;
  }

  // Calculates the total price without discount for the updated group -> renewal price
  @computed
  public get groupTotalPriceWithoutDiscount(): number {
    const existingQuantityPriceWithoutOwner = (this.groupSubscriptionInfo.quantity - 1) * this.priceForEveryNextUser;
    return this.priceForOneUser + this.assignedMembersPriceWithoutDiscount + existingQuantityPriceWithoutOwner;
  }

  // Calculates the total discount for the updated group, excluding the owner
  @computed
  public get groupTotalDiscount(): number {
    return this.assignedMembersDiscount - this.emptySeatDiscount;
  }

  // Calculates the final total price after applying discounts, excluding the owner
  @computed
  public get groupTotalPriceAfterDiscount(): number {
    return this.assignedMembersFinalPrice;
  }

  @computed
  public get groupOverviewWithoutOwner(): IGroupOverviewMembersOnly {
    return {
      type: 'membersOnly',
      groupMembersOverview: this.groupMembersOverview,
      assignedMembers: this.assignedMembers,
      candidatesForAssignment: this.candidatesForAssignment,
      groupTotalPriceWithoutDiscount: this.groupTotalPriceWithoutDiscount,
      groupTotalDiscount: this.assignedMembersDiscount,
      groupTotalPriceAfterDiscount: this.assignedMembersFinalPrice,
      currency: this.currency,
      priceForEveryNextUser: this.priceForEveryNextUser,
      groupSubscriptionInfo: this.groupSubscriptionInfo,
    };
  }

  @computed
  public get shouldRenderForwardingBlockerModal() {
    const { status } = this.groupSubscriptionInfo || {};
    return status
      && status !== STRIPE_SUBSCRIPTION_STATUS.ACTIVE
      && status !== STRIPE_SUBSCRIPTION_STATUS.TRIALING
      && status !== STRIPE_SUBSCRIPTION_STATUS.CANCELED
      && status !== STRIPE_SUBSCRIPTION_STATUS.INCOMPLETE_EXPIRED;
  }

  @computed
  private get existingAndAssignedMembersIds(): string[] {
    const existingMembers = [this.groupSubscriptionInfo.owner, ...this.groupSubscriptionInfo.members];
    const newlyAssignedMembers = Array.from(this.assignedMembers);
    const allMemberIds = [...existingMembers, ...newlyAssignedMembers].map(member => member!.id);

    // Use a Set to remove duplicates and convert back to an array
    return Array.from(new Set(allMemberIds));
  }

  public getSubscriptionInfo = new AsyncTask(async () => {
    const response = await this.stripeService.getSubscriptionInfo();

    if (response) {
      return this.setGroupSubscriptionInfo(response);
    }
  })

  public getUpcomingInvoice = new AsyncTask(async (quantity?: number | undefined) => {
    const response = await this.stripeService.getUpcomingInvoice(quantity);

    if (response) {
      return this.setUpcomingInvoice(response);
    }
  });

  public updateGroupSubscription = new AsyncTask(async () => {
    const dto = this.groupSubscriptionInfo.toDto(
      this.existingAndAssignedMembersIds,
      this.upcomingInvoice.coupon,
      this.existingAndAssignedMembersIds.length,
      this.groupTotalDiscount,
    );

    const response = await this.stripeService.updateGroupSubscription(dto);
    if (response) {
      this.setGroupSubscriptionInfo(response);
      await this.delayNavigation();
    }
  });

  private delayNavigation = () => {
    return new Promise<void>((resolve) => {
      setTimeout(() => {
        if (this.groupSubscriptionInfo?.paymentResolveUrl && this.shouldRenderForwardingBlockerModal) {
          return;
        } else {
          this.notification.success(this.i18n.t('manage_group:success.updating_group_subscription'));
          this.navigate(AppRoutes.ManageGroup);
        }
        resolve();
      }, 3000);
    });
  };

}
