import { GroupSubscriptionModel } from '../../../domain/model/GroupSubscriptionModel';
import { GroupSubscriptionUserModel } from '../../../domain/model/GroupSubscriptionUserModel';

export interface ICommonGroupProperties {
  assignedMembers: Set<GroupSubscriptionUserModel>;
  groupMembersOverview: Set<GroupSubscriptionUserModel>;
  candidatesForAssignment: GroupSubscriptionUserModel[];
  groupTotalPriceWithoutDiscount: number;
  groupTotalDiscount: number;
  groupTotalPriceAfterDiscount: number;
  currency: string;
  priceForEveryNextUser: number;
}

export interface IGroupOverviewMembersOnly extends ICommonGroupProperties {
  type: 'membersOnly';
  groupSubscriptionInfo: GroupSubscriptionModel;
}

export interface IGroupOverviewWithOwner extends ICommonGroupProperties {
  type: 'withOwner';
  owner: GroupSubscriptionUserModel | undefined;
  ownerPriceWithoutDiscount: number;
  ownerDiscount: number;
  ownerFinalPrice: number;
}

export type GroupOverviewType = IGroupOverviewMembersOnly | IGroupOverviewWithOwner;

export function isGroupOverviewWithOwner(data: GroupOverviewType): data is IGroupOverviewWithOwner {
  return data.type === 'withOwner';
}

export function isGroupOverviewMembersOnly(data: GroupOverviewType): data is IGroupOverviewMembersOnly {
  return data.type === 'membersOnly';
}
